import React, { useEffect } from 'react'
import { useParams } from 'react-router'
import { APIBASE } from '../config'

const UrlShortener = () => {
  const { id } = useParams<{ id: string }>()

  useEffect(() => {
    window.location.replace(`${APIBASE}/r/${id}`)
  }, [])

  return <div />
}

export default UrlShortener
