import * as Sentry from '@sentry/browser'
import moment from 'moment'
import React from 'react'
import NotificationSystem from 'react-notification-system'
import { useSelector } from 'react-redux'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import styled, { createGlobalStyle } from 'styled-components'
import 'react-datepicker/dist/react-datepicker.css'

import { Provider, h, notifications } from '@debbie/cortex/dist'
import { RootState } from '@debbie/cortex/dist/reducer'
import MoneyCalculator from '@debbie/iris/dist/components/MoneyCalculator'

import Signed from './components/Signed'
import Welcome from './components/Welcome'
import { ENABLE_SENTRY, SENTRY_DSN } from './config'
import LoggedInView from './LoggedInView'
import UrlShortener from './pages/UrlShortener'

if (ENABLE_SENTRY && SENTRY_DSN) Sentry.init({ dsn: SENTRY_DSN })

moment.locale()

const Wrapper = styled.div`
  * {
    font-family: 'Montserrat', sans-serif;
    box-sizing: border-box;
  }
`

const X = () => {
  const isAuthTokenLoaded = useSelector((s: RootState) =>
    h.isLoaded(s.authToken),
  )

  return (
    <Router>
      <Style />
      <Wrapper>
        <Switch>
          <Route path="/r/:id">
            <UrlShortener />
          </Route>
          <Route path="/signed">
            <Signed />
          </Route>
          <Route path="/">
            <Welcome hide={isAuthTokenLoaded} />
            {isAuthTokenLoaded && <LoggedInView />}
          </Route>
        </Switch>
      </Wrapper>
      <NotificationSystem
        ref={(r) => notifications.attachNotificationHandler(r)}
      />
      <MoneyCalculator />
    </Router>
  )
}

const App = () => (
  <Provider>
    <X />
  </Provider>
)

export default App

const Style = createGlobalStyle` 
  body {
    margin: 0;
    font-family: 'Montserrat',sans-serif;
    overflow: hidden;
    background-color: #F5F9FB;
  }

  a:focus {
    text-decoration: underline !important;
    outline: none;
  }

  * {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
  *:before,
  *:after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
  
  .__react_component_tooltip {
    max-width: 300px;
  }

  .react-datepicker__time-list {
    padding: 0;
  }

  .react-datepicker-wrapper {
    width: 70%;
  }

  .react-datepicker__input-container {
    display: block;
  }

  .react-datepicker__input-container input {
    width: 100%;
  }
`
